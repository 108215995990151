import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import BuyCreditsButton from './BuyCreditsButton';
import { FaPenFancy, FaTags, FaListAlt, FaCalendarAlt,FaMicrophone, FaChalkboardTeacher,FaBookOpen,FaFileAlt,FaClipboardList, FaRobot } from 'react-icons/fa'; // Importing icons
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import { Helmet } from 'react-helmet';
import SubscribeSchool from "./SubscribeSchool";

const Dashboard = () => {
  const tools = [
    { name: "Text Summarizer", link: "/note-summarizer", description: "Summarize large texts into concise summaries", category: "Writing Assistance", badge: "", isStudentTool: true },
    { name: "Bullet Point Answer Generator", link: "/bullet-point-generator", description: "Generate key points for quick answers", category: "Writing Assistance", isStudentTool: true },
    { name: "Mock Test Generator", link: "/mock-test-generator", description: "Generate quizzes to test your knowledge", category: "Student Assessment", isStudentTool: true },
    { name: "Math Solver", link: "/math-solver", description: "Solve complex math problems easily", category: "Subject-Specific", isStudentTool: true },
    { name: "Academic Essay Writer", link: "/essay-writer", description: "Write structured essays with AI assistance", category: "Writing Assistance", badge: "", isStudentTool: true },
    { name: "AI Paraphrasing Tool", link: "/quillbot", description: "Rephrase your content into better sentences", category: "Writing Assistance", isStudentTool: true },
    { name: "AI Lesson Planner", link: "/lesson-planner", description: "Quickly generate comprehensive lesson plans", category: "Lesson Planning", isStudentTool: true },
    { name: "AI Worksheet Generator", link: "/worksheet-generator", description: "Generate tailored worksheets instantly for all subjects", category: "Lesson Planning", isStudentTool: true },
    { name: "AI Homework Helper", link: "/homework-helper", description: "Your AI-Powered Homework Assistant", category: "AI Assistant", isStudentTool: true },
    { name: "Learn With Podcast", link: "/learn-with-podcast", description: "Generate engaging podcast discussions, and learn complex topics easily", category: "AI Podcast", isStudentTool: true },
    { name: "AI Teacher Assistant", link: "/teacher-assistant", description: "Your Personal Assistant for Teaching Help", category: "AI Assistant", isStudentTool: true },
  ];

  const categories = [
    { name: "Lesson Planning", description: "Streamline lesson creation with our AI tools", tools: ["AI Lesson Planner","AI Worksheet Generator"], icon: <FaCalendarAlt />, bgColor: "bg-red-200" },
    { name: "Writing Assistance", description: "Create & Enhance your writing quality", tools: ["Text Summarizer", "Academic Essay Writer", "Bullet Point Answer Generator", "AI Paraphrasing Tool"], icon: <FaTags />, bgColor: "bg-yellow-200" },
    { name: "Student Assessment", description: "Test knowledge with mock tests", tools: ["Mock Test Generator"], icon: <FaPenFancy />, bgColor: "bg-green-200" },
    { name: "Subject-Specific", description: "Tools tailored for specific subjects", tools: ["Math Solver"], icon: <FaListAlt />, bgColor: "bg-purple-200" },
    { name: "AI Assistant", description: "AI Assistant for Students & Teachers", tools: ["AI Homework Helper", "AI Teacher Assistant"], icon: <FaRobot />, bgColor: "bg-blue-200" },
    { name: "AI Podcast", description: "AI‑generated audio discussions", tools: ["Learn With Podcast"], icon: <FaMicrophone />, bgColor: "bg-orange-200" },
  ];
  const navigate = useNavigate();
  // Creating useRefs for each category
  const writingAssistanceRef = useRef(null);
  const studentAssessmentRef = useRef(null);
  const subjectSpecificRef = useRef(null);
  const lessonPlanningRef = useRef(null);
  const aiAssistantRef = useRef(null);
  const aiPodcastRef = useRef(null);
  const [isModalOpen, setModalOpen] = useState(false);

  // Mapping category names to refs
  const categoryRefs = {
    "Writing Assistance": writingAssistanceRef,
    "Student Assessment": studentAssessmentRef,
    "Subject-Specific": subjectSpecificRef,
    "Lesson Planning": lessonPlanningRef,
    "AI Assistant": aiAssistantRef,
    "AI Podcast": aiPodcastRef,
  };

  const scrollToSection = (categoryName) => {
    categoryRefs[categoryName].current.scrollIntoView({ behavior: "smooth" });
  };
  const [user] = useAuthState(auth);

  return (
    <>
    <Helmet> 
  <title>StudentAITools - AI Tools for Students & Teachers | Transforming Education with AI</title>
  <meta
    name="description"
    content="Discover AI-powered tools at StudentAITools, designed for both students and teachers. Streamline homework, lesson planning, task management, and more with our innovative AI solutions for education."
  />
</Helmet>

    {/* AI Education Tools Section */}
<div className="bg-gradient-to-r from-purple-400 to-blue-500 text-white py-12">
  <div className="container mx-auto text-center px-4">
    <h2 className="text-3xl sm:text-4xl font-bold mb-4">AI Tools for Education</h2>
    <p className="text-base sm:text-lg mb-8">Enhance your studying & teaching capabilities with our advanced AI tools.</p>

    <h3 className="text-xl sm:text-2xl font-semibold mb-6">Popular AI Tools:</h3>

    {/* Grid for mobile, Flexbox for larger screens */}
    <div className="grid grid-cols-2 gap-6 sm:flex sm:justify-center sm:space-x-8">
      {/* Tool: Lesson Planner */}
      <Link to='/lesson-planner' className="text-center">
        <div className="bg-white rounded-full p-4 inline-block mb-2">
          <FaChalkboardTeacher className="h-10 w-10 sm:h-12 sm:w-12 text-blue-500" />
        </div>
        <p className="text-sm sm:text-base font-semibold">Lesson Planner</p>
      </Link>

      {/* Tool: Homework Helper */}
      <Link to='/homework-helper' className="text-center">
        <div className="bg-white rounded-full p-4 inline-block mb-2">
          <FaBookOpen className="h-10 w-10 sm:h-12 sm:w-12 text-blue-500" />
        </div>
        <p className="text-sm sm:text-base font-semibold">Homework Helper</p>
      </Link>

      {/* Tool: Worksheet Generator */}
      <Link to='/worksheet-generator' className="text-center">
        <div className="bg-white rounded-full p-4 inline-block mb-2">
          <FaFileAlt className="h-10 w-10 sm:h-12 sm:w-12 text-blue-500" />
        </div>
        <p className="text-sm sm:text-base font-semibold">Worksheet Generator</p>
      </Link>

      {/* Tool: Quiz Generator */}
      <Link to='/mock-test-generator' className="text-center">
        <div className="bg-white rounded-full p-4 inline-block mb-2">
          <FaClipboardList className="h-10 w-10 sm:h-12 sm:w-12 text-blue-500" />
        </div>
        <p className="text-sm sm:text-base font-semibold">Quiz Generator</p>
      </Link>
    </div>
  </div>
</div>


<div className="container mx-auto p-4">

{/* Popular Category Section */}
<div className="my-6">
  <h3 className="text-2xl font-semibold mb-6">Popular Categories</h3>
  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 mb-12">
    {categories.map((category, index) => (
      <div
        key={index}
        onClick={() => scrollToSection(category.name)} // Scroll to section
        className={`p-6 rounded-lg shadow-lg transition-shadow duration-300 cursor-pointer ${category.bgColor} text-gray-800 hover:border-blue-500 hover:shadow-2xl border-2 flex items-center`} // Flex layout for icon and text
      >
        {/* Icon on the left */}
        <div className="mr-4 text-4xl text-gray-700">
          {category.icon}
        </div>

        {/* Center content */}
        <div className="flex flex-col justify-center">
          <h4 className="text-xl font-semibold mb-2">{category.name}</h4>
          <p className="text-gray-500">{category.description}</p>
        </div>
      </div>
    ))}
  </div>
</div>

      {/* Tools by Category Section */}
      {categories.map((category, index) => (
        <div key={index} ref={categoryRefs[category.name]} className="mb-12">

          {category.name=='Writing Assistance' && <h3 className="text-2xl font-semibold mb-4">✍ Writing Assistance</h3>}
          {category.name=='Student Assessment' && <h3 className="text-2xl font-semibold mb-4">👩‍🎓 Student Assessment</h3>}
          {category.name=='Subject-Specific' && <h3 className="text-2xl font-semibold mb-4">📚 Subject-Specific</h3>}
          {category.name=='Lesson Planning' && <h3 className="text-2xl font-semibold mb-4">🎓 Lesson Planning</h3>}
          {category.name=='AI Assistant' && <h3 className="text-2xl font-semibold mb-4">🤖 AI Assistant</h3>}
          {category.name=='AI Podcast' && <h3 className="text-2xl font-semibold mb-4">🎙️ AI Podcast</h3>}

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
            {tools
              .filter((tool) => tool.category === category.name)
              .map((tool, toolIndex) => (
                <div
                  key={toolIndex}
                  onClick={() => navigate(tool.link)}
                  className={`p-6 rounded-lg shadow-lg transition-shadow duration-300 cursor-pointer ${
                    tool.isStudentTool
                      ? 'bg-blue-500 text-white border-blue-500 relative'
                      : 'bg-white text-gray-800 hover:border-blue-500 hover:shadow-2xl'
                  } border-2`}
                >
                  {tool.badge && (
                    <span className="absolute top-0 right-0 bg-white text-gray-800 py-1 px-2 rounded-lg">
                      {tool.badge}
                    </span>
                  )}
                  <h3 className="text-xl font-semibold mb-3">{tool.name}</h3>
                  <p>{tool.description}</p>

                  {/* Buttons for Visit/Get Discount */}
                  <div className="mt-4">
                    {tool.isStudentTool ? (
                      <button
                        onClick={() => navigate(tool.link)}
                        className="bg-white text-blue-800 py-2 px-4 rounded hover:bg-white transition duration-300"
                      >
                        Open
                      </button>
                    ) : (
                      <a
                        href={tool.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
                      >
                        Open
                      </a>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      ))}

      {/* Credits Button */}
      {user && <BuyCreditsButton />}
    </div>
    <Footer/>
    </>
  );
};

export default Dashboard;
