// src/googleVisionService.js

export const transcribeMathFromImage = async (imageFile) => {
    const apiKey = 'AIzaSyAzXhzvSkT8rMS-xyalsmxOArRi6VAmQF4'
    const endpoint = `https://vision.googleapis.com/v1/images:annotate?key=${apiKey}`;
  
    const base64Image = await toBase64(imageFile); // Convert image to Base64
  
    const body = {
      requests: [
        {
          image: {
            content: base64Image,
          },
          features: [
            {
              type: 'TEXT_DETECTION',
              maxResults: 1,
            },
          ],
        },
      ],
    };
  
    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
  
      const data = await response.json();
      const extractedText = data.responses[0].fullTextAnnotation?.text || '';
      return extractedText;
    } catch (error) {
      console.error('Error with Google Vision API:', error);
      return null;
    }
  };
  
  // Utility to convert image file to base64
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]); // Get the base64 part only
      reader.onerror = (error) => reject(error);
    });
  