import React, { useState, useEffect } from "react";
import { summarizeText } from "../openaiService";
import { getDatabase, ref, set, push, get, update } from "firebase/database";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate } from "react-router-dom";
import * as pdfjsLib from "pdfjs-dist";
import BuyCreditsButton from './BuyCreditsButton'; // Import the BuyCreditsButton component
import { extractTextFromDocOrDocx } from "../fileService"; // Import the DOC/DOCX extraction service
import { FaFileUpload, FaClipboard, FaPaste, FaCompressAlt, FaClipboardList, FaDownload, FaGraduationCap, FaBriefcase, FaPenNib,
  FaListAlt, FaFileAlt, FaRegEdit
 } from "react-icons/fa";
import Footer from "./Footer";
import LoginModal from "./LoginModal";
import { Helmet } from 'react-helmet';
import Feedback from "./Feedback";
import NewsletterCard from "./NewsletterCard";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { motion } from 'framer-motion';

const MAX_TOKENS = 2000; // OpenAI max tokens for summarizing

// Set the worker source for pdfjs-dist
pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.mjs`;
const GOOGLE_VISION_API_URL = `https://vision.googleapis.com/v1/images:annotate?key=${process.env.REACT_APP_GOOGLE_VISION_API_KEY}`;

const NoteSummarizer = () => {
  const [inputText, setInputText] = useState("");
  const [wordCount, setWordCount] = useState(500); // Default word count
  const [summary, setSummary] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [viewMode, setViewMode] = useState(false); // To hide controls in view mode
  const [summarizeLimit, setSummarizeLimit] = useState(2); // Limit for users
  const [isModalOpen, setModalOpen] = useState(false);
  const [numPages, setNumPages] = useState(1); // Limit for users
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const location = useLocation();
  const database = getDatabase();
  var tool= { name: "Note Summarizer"}

  useEffect(() => {
    if (user) {
      const fetchUserData = async () => {
        const userRef = ref(database, `users/${user.uid}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const userData = snapshot.val();
          setSummarizeLimit(userData.summarizeLimit || 2); // Initialize limit if missing
        }
      };
      fetchUserData();
    }

    if (location.state?.summary) {
      setSummary(location.state.summary);
      setViewMode(true);
    }else{
      setSummary('');
      setViewMode(false);
    }
  }, [user, location, database]);

  // Handle summarizing text
  const handleSummarize = async (e) => {
    e.preventDefault();
    if (!user) {
      setModalOpen(true);
      return;
    }
    setError("");
    setSummary("");
    setLoading(true);

    if (!inputText.trim()) {
      setError("Please enter some text to summarize or upload a file.");
      setLoading(false);
      return;
    }

    if (summarizeLimit <= 0) {
      toast.error("Not enough credits. Please purchase more credits.");
      setLoading(false);
      return;
    }

    try {
      const result = await summarizeText(inputText, wordCount);
      setSummary(result);

      // Save the summary to Firebase under the logged-in user's uid
      if (user) {
        const summaryRef = push(ref(database, `summaries/${user.uid}`));
        await set(summaryRef, {
          originalText: inputText,
          summary: result,
          wordCount: wordCount,
          createdAt: new Date().toISOString(),
          tool: "Note Summarizer"
        });

        toast.success("Saved to Documents!");

        // Deduct 1 credit per summary
        const newLimit = summarizeLimit - numPages;
        setSummarizeLimit(newLimit);
        const userRef = ref(database, `users/${user.uid}`);
        await update(userRef, { summarizeLimit: newLimit });
      }
    } catch (error) {
      setError("There was an issue summarizing the text. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Handle file upload for PDF, DOC, or DOCX
  const handleFileUpload = async (e) => {
    const uploadedFile = e.target.files[0];
    if (!uploadedFile) return;

    const fileType = uploadedFile.name.split(".").pop();
    if (fileType === "pdf") {
      await extractTextFromPDF(uploadedFile);
    } else if (fileType === "doc" || fileType === "docx") {
      let extractedText = await extractTextFromDocOrDocx(uploadedFile);
      setInputText(extractedText);
    }
  };

  // Extract text from PDF
  const extractTextFromPDF = async (pdfFile) => {
    try {
      const reader = new FileReader();
  
      reader.onload = async function () {
        const typedArray = new Uint8Array(this.result);
        const pdf = await pdfjsLib.getDocument(typedArray).promise;
  
        if (!pdf || !pdf.numPages) {
          throw new Error("Failed to load the PDF document.");
        }
  
        let extractedText = "";
        const totalCreditsNeeded = pdf.numPages;
        setNumPages(pdf.numPages);
        if (summarizeLimit < totalCreditsNeeded) {
          toast.error(`You need ${totalCreditsNeeded - summarizeLimit} more credits to summarize this PDF.`);
          return;
        }

        // Create a toast that will update
        const toastId = toast.loading(`Processing page 1 of ${pdf.numPages}...`);

        // Loop through each page of the PDF
        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
  
          // Create a canvas element to render the page
          const viewport = page.getViewport({ scale: 1.5 });
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          canvas.height = viewport.height;
          canvas.width = viewport.width;
  
          await page.render({ canvasContext: context, viewport }).promise;
  
          // Convert the canvas to a base64 image
          const base64Image = getBase64FromCanvas(canvas);
  
          // Prepare request body for Google Cloud Vision API
          const requestBody = {
            requests: [
              {
                image: {
                  content: base64Image,
                },
                features: [
                  {
                    type: "DOCUMENT_TEXT_DETECTION",
                  },
                ],
              },
            ],
          };
  
          const response = await fetch(GOOGLE_VISION_API_URL, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          });
  
          const data = await response.json();
  
          if (data.responses && data.responses[0].fullTextAnnotation) {
            extractedText += data.responses[0].fullTextAnnotation.text + " ";
          } else {
            throw new Error("Failed to extract text from the PDF. Please try again.");
          }
  
          // Update toast with progress
          if(toastId){
            toast.update(toastId, {
              render: `Processing page ${i} of ${pdf.numPages}...`,
              type: "info",
              isLoading: true,
              autoClose: false,
            });
          }
          // Deduct 1 credit per page from the summarizeLimit
          const newLimit = summarizeLimit - 1;
          console.log(newLimit);
          setSummarizeLimit(newLimit);
          await update(ref(getDatabase(), `users/${user.uid}`), { summarizeLimit: newLimit });
        }

        setInputText(extractedText);
        toast.dismiss(toastId);
        toast.success("PDF uploaded. Ready to summarize.");
      };
  
      reader.readAsArrayBuffer(pdfFile);
    } catch (error) {
      setError("Error extracting text from PDF.");
      toast.error("Error extracting text from the PDF. Please try again.");
    }
  };

  // Convert canvas to base64 image
  const getBase64FromCanvas = (canvas) => {
    return canvas.toDataURL("image/png").split(",")[1]; // Get base64 part
  };

  // Handle word count change from slider or input box
  const handleWordCountChange = (value) => {
    const newValue = Math.min(value, MAX_TOKENS);
    setWordCount(newValue);
  };

  const handleDownload = () => {
    if (!summary) {
      toast.error("No content to download!");  // Show error if there's no summary generated
      return;
    }
  
    const blob = new Blob([summary], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    
    const link = document.createElement("a");
    link.href = url;
    link.download = `summary.txt`;  // Filename based on the topic
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleCopy = () => {
    if (summary) {
      navigator.clipboard.writeText(summary)
        .then(() => {
          toast.success("Text copied to clipboard!");
        })
        .catch((error) => {
          console.error("Error copying text: ", error);
          toast.error("Failed to copy text.");
        });
    } else {
      toast.error("No text to copy!");
    }
  };

  return (
    <>
    <Helmet>
        <title>Note Summarizer - Summarize Text with AI | StudentAITools</title>
        <meta
          name="description"
          content="Summarize lengthy notes and text with our AI-powered Note Summarizer. Perfect for students, professionals, and researchers needing concise summaries."
        />
      </Helmet>
      <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <div className="w-full max-w-5xl flex justify-between items-center mb-4">
          {viewMode && (
            <button
              onClick={() => navigate("/documents")}
              className="text-blue-600 hover:underline"
            >
              &larr; Back to Documents
            </button>
          )}
          
          {user && (
          <div className="text-right">
            <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
              Credit Left: {summarizeLimit}
            </span>
          </div>
          )}
      </div>

        {/* Main Header */}
        <h1 className="text-3xl font-bold text-center mb-4">Text Summarizer</h1>
        <p className="text-lg text-gray-600 text-center mb-8">
          Simplify and condense long texts into concise summaries in seconds
        </p>
        
        {error && <p className="text-red-500 text-center mb-4">{error}</p>}


        <motion.div className="bg-white shadow-lg rounded-lg p-8 max-w-5xl w-full flex flex-col lg:flex-row"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, ease: 'easeOut' }}
        viewport={{ once: true, amount: 0.2 }}>


          {!viewMode && (
            <div className="w-full lg:w-1/2 pr-4 lg:border-r mb-4 lg:mb-0">
              <h2 className="text-lg font-bold mb-4">Original Version</h2>
              <form onSubmit={handleSummarize} className="space-y-4">
                <textarea
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                  className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
                  rows="6"
                  placeholder="Paste your notes or text here..."
                ></textarea>

                <div className="flex justify-between items-center mt-4">
                  <div></div>
                  {/* FaFileUpload Icon (Left of Paraphrasing Mode Selection) */}
                    <label className="group cursor-pointer flex items-center">
                      <FaFileUpload className="text-gray-500 hover:text-gray-700 h-5 w-5 mr-2" />
                      Upload Doc
                      {/* File Input Hidden */}
                      <input
                        type="file"
                        accept=".doc,.docx,.pdf"
                        onChange={handleFileUpload}
                        className="hidden"
                      />
                    </label>
                  {/* Word Count Display */}
                  {/* <span className="text-sm text-gray-600">{inputText.length} / 750 words</span> */}
                </div>
                <div className="mt-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700">Choose Word Count</label>
                  <input
                    type="number"
                    value={wordCount}
                    onChange={(e) => handleWordCountChange(Number(e.target.value))}
                    className="w-full px-4 py-2 border rounded-lg mb-2"
                    placeholder="Enter desired word count"
                    max={MAX_TOKENS}
                  />
                  <input
                    type="range"
                    min="100"
                    max={MAX_TOKENS}
                    value={wordCount}
                    onChange={(e) => handleWordCountChange(Number(e.target.value))}
                    className="w-full"
                  />
                  <p className="text-gray-500 text-sm mt-1">Max word count: {MAX_TOKENS}</p>
                </div>

                <button type="submit" className="w-full bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition duration-300 flex justify-center items-center" disabled={loading}>
                  {loading ? (
                    <div className="flex items-center space-x-2">
                      <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      <span>Summarizing...</span>
                    </div>
                  ) : "Summarize"}
                </button>
              </form>
            </div>
          )}

          {/* Summarized Version result */}
          <div className={viewMode ? "w-full pl-4" : "w-full lg:w-1/2 pl-4"}>
            <h2 className="text-lg font-bold mb-4">Summarized Version</h2>
    
            {/* Summarized text output */}
            <div className="bg-gray-100 p-4 rounded-lg h-72 lg:h-80 relative overflow-auto">
              {/* Summarized text */}
              <p className="text-gray-700 whitespace-pre-wrap">
                {summary || "Your Summarized text will appear here..."}
              </p>
            </div>
            {/* Buttons below the result */}
            <div className="flex justify-between mt-4">
              {/* Download Button */}
              <button
                onClick={handleDownload}
                className="bg-gray-600 text-white px-4 py-2 rounded-lg hover:bg-gray-700 transition duration-300"
              >
                Download
              </button>

              {/* Copy to Clipboard Button */}
              <button
                onClick={handleCopy}
                className="text-gray-500 hover:text-gray-700"
                title="Copy to Clipboard"
              >
                <FaClipboard className="text-xl text-gray-600 hover:text-blue-600" />
              </button>
            </div>

          </div>
        </motion.div>
        
      <Feedback tool={{"name":"Note Summarizer"}} />
    

        {!viewMode && (
        <motion.div className="max-w-5xl w-full bg-white shadow-lg rounded-lg p-8 mt-8"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: 'easeOut' }}
          viewport={{ once: true, amount: 0.2 }}>
          <h2 className="text-2xl font-bold mb-4">What is a Text Summarizer?</h2>
          <p className="text-gray-700 mb-4">
            The Text Summarizer is an AI-powered tool designed to condense lengthy articles, research papers, and documents into concise summaries. It extracts key points and delivers clear, concise versions of the content without losing important information. Whether you're a student, professional, or writer, our Text Summarizer helps you quickly understand complex material.
          </p>

          <h3 className="text-xl font-semibold mb-2">Why Use a Text Summarizer?</h3>
          <table className="min-w-full bg-white border-collapse mb-4">
          <thead>
            <tr>
              <th className="py-3 px-5 bg-gray-100 border-b text-left text-sm font-semibold text-gray-800">Benefit</th>
              <th className="py-3 px-5 bg-gray-100 border-b text-left text-sm font-semibold text-gray-800">Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="py-4 px-5 border-b text-gray-700 font-bold">Save Time</td>
              <td className="py-4 px-5 border-b text-gray-700">
                Quickly summarize long texts to extract key points in seconds.
              </td>
            </tr>
            <tr>
              <td className="py-4 px-5 border-b text-gray-700 font-bold">Improve Productivity</td>
              <td className="py-4 px-5 border-b text-gray-700">
                Summarized texts allow you to focus only on relevant content, boosting productivity.
              </td>
            </tr>
            <tr>
              <td className="py-4 px-5 border-b text-gray-700 font-bold">Extract Key Points</td>
              <td className="py-4 px-5 border-b text-gray-700">
                Identify the most important points from lengthy documents quickly.
              </td>
            </tr>
            <tr>
              <td className="py-4 px-5 border-b text-gray-700 font-bold">Enhance Comprehension</td>
              <td className="py-4 px-5 border-b text-gray-700">
                Simplifies complex information for easier understanding.
              </td>
            </tr>
            <tr>
              <td className="py-4 px-5 border-b text-gray-700 font-bold">Boost SEO</td>
              <td className="py-4 px-5 border-b text-gray-700">
                Summaries improve search engine visibility with optimized content.
              </td>
            </tr>
          </tbody>
        </table>

          <h3 className="text-xl font-semibold mb-2">Why Choose StudentAITools?</h3>
          <p className="text-gray-700 mb-4">
            StudentAITools offers a reliable and fast Text Summarizer powered by advanced AI algorithms. We provide accurate and readable summaries, allowing you to save time and focus on what's important. Our tool is easy to use, accessible for students, researchers, professionals, and anyone who needs to distill long texts into brief summaries.
          </p>

          <h3 className="text-xl font-semibold mb-2">How to Use Our Text Summarizer Tool</h3>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 my-6">
            {/* Step 1 Card */}
            <div className="bg-white shadow-lg rounded-lg p-6 text-center flex flex-col items-center">
              <FaPaste className="h-12 w-12 text-blue-500 mb-4" />
              <h3 className="font-bold text-lg mb-2">Step 1</h3>
              <p className="text-gray-600">Paste or upload the text or document you want to summarize.</p>
            </div>

            {/* Step 2 Card */}
            <div className="bg-white shadow-lg rounded-lg p-6 text-center flex flex-col items-center">
              <FaCompressAlt className="h-12 w-12 text-green-500 mb-4" />
              <h3 className="font-bold text-lg mb-2">Step 2</h3>
              <p className="text-gray-600">Click "Summarize" and let the AI process the text.</p>
            </div>

            {/* Step 3 Card */}
            <div className="bg-white shadow-lg rounded-lg p-6 text-center flex flex-col items-center">
              <FaClipboardList className="h-12 w-12 text-purple-500 mb-4" />
              <h3 className="font-bold text-lg mb-2">Step 3</h3>
              <p className="text-gray-600">Review the summary, which provides the key points of the original content.</p>
            </div>

            {/* Step 4 Card */}
            <div className="bg-white shadow-lg rounded-lg p-6 text-center flex flex-col items-center">
              <FaDownload className="h-12 w-12 text-red-500 mb-4" />
              <h3 className="font-bold text-lg mb-2">Step 4</h3>
              <p className="text-gray-600">Copy, download, or edit the summary as needed.</p>
            </div>
          </div>

          <h3 className="text-xl font-semibold mb-2">Tips for Best Results</h3>
          <ul className="list-disc list-inside text-gray-700 mb-4">
            <li>Provide clear, well-structured content for better summaries.</li>
            <li>Use the tool to summarize long articles, research papers, or reports.</li>
            <li>Adjust the length of the summary by specifying the desired level of detail.</li>
          </ul>

          <h3 className="text-xl font-semibold mb-2">Who Can Benefit from This Text Summarizer Tool?</h3>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 my-6">
          {/* Students */}
          <div className="bg-white shadow-lg rounded-lg p-6 text-center flex flex-col items-center">
            <FaGraduationCap className="h-12 w-12 text-blue-500 mb-4" />
            <h3 className="font-bold text-lg mb-2">Students</h3>
            <p className="text-gray-600">
              Perfect for students summarizing academic papers, textbooks, or lecture notes, helping streamline study sessions and retain critical concepts faster.
            </p>
          </div>

          {/* Professionals */}
          <div className="bg-white shadow-lg rounded-lg p-6 text-center flex flex-col items-center">
            <FaBriefcase className="h-12 w-12 text-green-500 mb-4" />
            <h3 className="font-bold text-lg mb-2">Professionals</h3>
            <p className="text-gray-600">
              Ideal for professionals who need concise summaries of long reports, emails, or presentations to improve productivity and decision-making.
            </p>
          </div>

          {/* Writers and Researchers */}
          <div className="bg-white shadow-lg rounded-lg p-6 text-center flex flex-col items-center">
            <FaPenNib className="h-12 w-12 text-purple-500 mb-4" />
            <h3 className="font-bold text-lg mb-2">Writers & Researchers</h3>
            <p className="text-gray-600">
              Essential for writers and researchers to get quick overviews of source material, ensuring efficient content creation and research progress.
            </p>
          </div>
        </div>

          <h3 className="text-xl font-semibold mb-2">Best Free Text Summarizer Tools</h3>
          <ul className="list-disc list-inside mb-4 text-gray-700">
            <li><strong>Jasper AI:</strong> Known for generating high-quality summaries, Jasper AI is versatile for both short and long texts.</li>
            <li><strong>SummarizeBot:</strong> A powerful tool for summarizing documents, PDFs, and webpages.</li>
            <li><strong>Copy.ai:</strong> Quickly creates summaries of content in a user-friendly manner, perfect for blog posts and articles.</li>
            <li><strong>Quillbot:</strong> Specializes in rephrasing and summarizing long articles into shorter versions for easy understanding.</li>
          </ul>

          <h3 className="text-xl font-semibold mb-2">Disclaimer</h3>
          <p className="text-gray-700">
            Our AI Text Summarizer tool aims for high accuracy. However, achieving perfection is still challenging. Please note: the predictions are for demonstration purposes only. Do not rely on them for real-world applications. Use caution, as we are not liable for any damages incurred. We strongly recommend using human oversight when using our products.
          </p>
        </motion.div>
        )}
        <NewsletterCard/>
        {user && (
          <BuyCreditsButton />
        )}

      </div>

      {!viewMode && (
        <Footer />
      )}

    <LoginModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />

    </>
  );
};

export default NoteSummarizer;
