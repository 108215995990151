import React, { useState } from 'react';
import { getDatabase, ref, push, set } from 'firebase/database';
import { auth } from '../firebase'; // Assuming Firebase is already configured
import { useAuthState } from 'react-firebase-hooks/auth';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BuyCreditsButton from './BuyCreditsButton'; // Import the BuyCreditsButton component
import { FaDownload } from 'react-icons/fa'; // Import download icon
import Feedback from "./Feedback";
import NewsletterCard from "./NewsletterCard";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { motion } from 'framer-motion';

const MockTestImporter = () => {
  const [file, setFile] = useState(null);
  const [quizTitle, setQuizTitle] = useState(""); // State for Quiz Title
  const [questionType, setQuestionType] = useState("text"); // State for Question Type (Text or Image-Based)
  const [user] = useAuthState(auth);
  const database = getDatabase();

  var tool= { name: "Mock Test Importer"}
  // Handle file upload
  const handleFileUpload = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile && uploadedFile.type === 'application/json') {
      setFile(uploadedFile);
    } else {
      toast.error('Please upload a valid JSON file.');
    }
  };

  // Handle Import button click
  const handleImportQuiz = async () => {
    if (!file) {
      toast.error('No file uploaded.');
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const quizData = JSON.parse(e.target.result);

        // Check if the data is an array and each item contains the necessary keys
        if (!Array.isArray(quizData) || !quizData.every(item => item.question && item.options && item.correctAnswer)) {
          throw new Error("Invalid JSON structure");
        }

        if (user) {
          const quizRef = push(ref(database, `mocktests/${user.uid}`));

          // Save quiz data to the database
          await set(quizRef, {
            originalText: quizTitle || "Imported Quiz", // Save quiz title
            createdAt: new Date().toISOString(),
            questions: quizData,
          });

          toast.success('Quiz saved to Documents!');
        } else {
          toast.error('You need to be logged in to import a quiz.');
        }
      } catch (error) {
        console.error("Error parsing JSON or saving quiz:", error);
        toast.error('Error parsing the JSON file. Make sure the file is in the correct format.');
      }
    };

    reader.readAsText(file);
  };

  // Handle Sample JSON download based on selected Question Type
  const handleSampleDownload = () => {
    const sampleData =
      questionType === "image"
        ? [
            {
              correctAnswer: "Cardinal",
              imageUrl: "https://app.studentaitools.com/api/uploads/e2b92224-ddc1-4a9c-b87a-3ff3861fbb2c.jpeg",
              options: ["Sparrow", "Eagle", "Cardinal", "Penguin"],
              question: "Which bird is known for its bright red color and crest on the head?"
            },
            {
              correctAnswer: "Dove",
              imageUrl: "https://app.studentaitools.com/api/uploads/e4200fa1-9ae5-43ff-b84e-099c6aed5ad6.jpeg",
              options: ["Dove", "Owl", "Hawk", "Swan"],
              question: "Which bird is the symbol of peace?"
            },
            {
              correctAnswer: "Parrot",
              imageUrl: "https://app.studentaitools.com/api/uploads/c2bebf13-df85-4e60-8101-b55e6c207bd8.jpeg",
              options: ["Parrot", "Peacock", "Pigeon", "Falcon"],
              question: "Which bird is known for its ability to imitate human speech?"
            }
          ]
        : [
            {
                correctAnswer: "Cardinal",
                options: ["Sparrow", "Eagle", "Cardinal", "Penguin"],
                question: "Which bird is known for its bright red color and crest on the head?"
              },
              {
                correctAnswer: "Dove",
                options: ["Dove", "Owl", "Hawk", "Swan"],
                question: "Which bird is the symbol of peace?"
              },
              {
                correctAnswer: "Parrot",
                options: ["Parrot", "Peacock", "Pigeon", "Falcon"],
                question: "Which bird is known for its ability to imitate human speech?"
              }
          ];

    const blob = new Blob([JSON.stringify(sampleData, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'sample-quiz.json');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <motion.div className="max-w-5xl w-full bg-white shadow-lg rounded-lg p-8 mt-8"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: 'easeOut' }}
          viewport={{ once: true, amount: 0.2 }}>
        <h2 className="text-2xl font-bold text-center mb-6">Import Mock Test</h2>

        {/* Quiz Title Input */}
        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-700">
            Quiz Title
          </label>
          <input
            type="text"
            value={quizTitle}
            onChange={(e) => setQuizTitle(e.target.value)}
            className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
            placeholder="Enter quiz title..."
            required
          />
        </div>

        {/* File Upload */}
        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-700">
            Upload JSON File
          </label>
          <input
            type="file"
            accept="application/json"
            onChange={handleFileUpload}
            className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
        </div>

        {/* Question Type Selection */}
        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-700">Select Question Type</label>
          <div className="flex space-x-4">
            <label className="flex items-center">
              <input
                type="radio"
                name="questionType"
                value="text"
                checked={questionType === "text"}
                onChange={(e) => setQuestionType(e.target.value)}
                className="form-radio text-blue-600"
              />
              <span className="ml-2">Text-Based</span>
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                name="questionType"
                value="image"
                checked={questionType === "image"}
                onChange={(e) => setQuestionType(e.target.value)}
                className="form-radio text-blue-600"
              />
              <span className="ml-2">Image-Based</span>
            </label>
          </div>
        </div>

        {/* Sample JSON Download Button */}
        <div className="mb-4">
          <button
            className="w-full bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition duration-300 flex items-center justify-center"
            onClick={handleSampleDownload}
          >
            <FaDownload className="mr-2" />
            Download Sample JSON
          </button>
        </div>

        {/* Import Button */}
        <button
          onClick={handleImportQuiz}
          className="w-full bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition duration-300"
        >
          Import Quiz
        </button>

      </motion.div>
        <Feedback tool={{"name":"Mock Test Importer"}} />
        <NewsletterCard />

      <BuyCreditsButton />
    </div>
  );
};

export default MockTestImporter;