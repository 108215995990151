import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { generateResponse, transcribeVoice } from "../openaiService";
import Loader from "./Loader";
import { getDatabase, ref, get, update } from "firebase/database";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Feedback from "./Feedback";
import NewsletterCard from "./NewsletterCard";
import { FaMicrophone, FaPaperPlane, FaCopy, FaRobot, FaClock, FaTasks, FaCheckCircle, FaUserTie,FaClipboardList, FaUser, FaGraduationCap, FaChalkboardTeacher, FaPenFancy, FaBlog, FaSearch} from "react-icons/fa";
import Footer from "./Footer";
import LoginModal from "./LoginModal";
import BuyCreditsButton from './BuyCreditsButton'; 
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';

const TeacherAssistant = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [recording, setRecording] = useState(false);
  const [messageLimit, setMessageLimit] = useState(50); // Default message limit
  const [messageCount, setMessageCount] = useState(0);
  const [user] = useAuthState(auth); // Firebase auth for getting user details
  const [isModalOpen, setModalOpen] = useState(false);

  // Initial prompt from the chatbot
  const initialPrompt = "Hello! 👋 I'm your Teacher's Assistant, ready to help you stay organized and manage your tasks with ease.\n\nHere’s how I can assist you:\n\n • 🗓️ Offer tips for setting up and managing reminders • 📋 Help organize your duties and responsibilities • ✅ Assist with task management and prioritization • 📆 Support in creating and maintaining effective schedules\n\nHow can I support you today?";

  // Set the initial message and fetch message limit on mount
  useEffect(() => {
    if (user) {
      fetchUserCredits(); // Fetch message limit from Firebase
      loadMessagesFromLocalStorage(); // Load previous chat from localStorage if any
    } else {
      setMessages([{ from: "bot", text: initialPrompt }]);
    }
  }, [user]);

  // Fetch message limit from Firebase for the logged-in user
  const fetchUserCredits = async () => {
    const db = getDatabase();
    const userRef = ref(db, `users/${user.uid}`);
    const snapshot = await get(userRef);
    if (snapshot.exists()) {
      const userData = snapshot.val();
      setMessageLimit(userData.teacher_assistant_message_limit || 50); // Default 50 if not set
      setMessageCount(userData.teacher_assistant_message_count || 0);
    } else {
      // If no data exists, set default limit in Firebase
      await update(userRef, { teacher_assistant_message_limit: 50 });
      setMessageLimit(50);

      await update(userRef, { teacher_assistant_message_count: 0 });
      setMessageCount(0);
    }
  };

  // Load messages from localStorage if they exist
  const loadMessagesFromLocalStorage = () => {
    const storedMessages = localStorage.getItem(`teacherChat_${user.uid}`);
    if (storedMessages) {
      setMessages(JSON.parse(storedMessages));
    } else {
      setMessages([{ from: "bot", text: initialPrompt }]);
    }
  };

  // Save messages to localStorage
  const saveMessagesToLocalStorage = (newMessages) => {
    localStorage.setItem(`teacherChat_${user.uid}`, JSON.stringify(newMessages));
  };

  // Handle the "Send" button click
  const handleSend = async () => {
    try {
      if (!user) {
        setModalOpen(true);
        return;
      }
      
      if (!input.trim()) return;

      // Check if the message limit has been reached
      if (messageLimit <= 0) {
        toast.error("You have reached the free limit of Teacher Assistant. Buy credits for more messages!");
        return;
      }

      const newMessages = [...messages, { from: "user", text: input }];
      setMessages(newMessages);
      saveMessagesToLocalStorage(newMessages); // Save the conversation to localStorage
      setInput(""); // Clear the input box
      setLoading(true);

      // Add the loader below the user's message
      const loaderMessages = [...newMessages, { from: "bot", text: "loading" }];
      setMessages(loaderMessages);

      // Check if it's an educational question
      const response = await generateResponse(input,'teacher');

      // Remove the loader once the response is ready
      const filteredMessages = loaderMessages.filter(message => message.text !== "loading");

      if (response.isEducational) {
        const updatedMessages = [...filteredMessages, { from: "bot", text: response.answer }];
        setMessages(updatedMessages);
        saveMessagesToLocalStorage(updatedMessages); // Save the updated conversation
      } else {
        const updatedMessages = [...filteredMessages, { from: "bot", text: "I can’t help you with this, Please ask me only educational questions" }];
        setMessages(updatedMessages);
        saveMessagesToLocalStorage(updatedMessages); // Save the updated conversation
      }

      // Deduct from message limit in Firebase
      deductMessageLimit();

      setLoading(false);
    }
    catch (error) {
      toast.error("There was an issue generating response from Chatbot. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Deduct 1 from the message limit in Firebase
  const deductMessageLimit = async () => {
    const db = getDatabase();
    const userRef = ref(db, `users/${user.uid}`);
    const newLimit = messageLimit - 1;
    setMessageLimit(newLimit);
    await update(userRef, { teacher_assistant_message_limit: newLimit });

    const newCount = messageCount + 1;
    setMessageCount(newCount);
    await update(userRef, { teacher_assistant_message_count: newCount });
  };

    // Handle voice transcription
    const handleVoice = async () => {
      setRecording(true);
      const transcription = await transcribeVoice(); // Transcribing voice using OpenAI
      setInput(transcription); // Set transcription to input box
      setRecording(false);
    };


  // Handle Clear Button (clears localStorage and resets chat)
  const handleClear = () => {
    setMessages([{ from: "bot", text: initialPrompt }]);
    localStorage.removeItem(`teacherChat_${user.uid}`); // Clear the stored conversation
  };

  // Handle keypress event on the input (Enter key)
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey && input.trim()) {
      e.preventDefault();
      handleSend();
    }
  };

  // Copy text to clipboard
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard!");
  };

  return (
    <>
    <Helmet> 
    <title>AI Teacher's Assistant - Organize and Manage Tasks | StudentAITools</title>
    <meta
      name="description"
      content="Enhance your classroom organization with our AI Teacher's Assistant. Manage tasks, set reminders, and create effective schedules effortlessly."
    />
  </Helmet>

    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      {/* Main Header */}
      <h1 className="text-3xl font-bold text-center my-4">AI Teacher's Assistant</h1>
        <p className="text-lg text-gray-600 text-center mb-8">
        Your Personal Assistant for Task Management and Teaching Help
      </p>

      <motion.div className="max-w-5xl mx-auto p-6 bg-white rounded-lg shadow-lg" 
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, ease: 'easeOut' }}
      viewport={{ once: true, amount: 0.2 }}>
        <div className="flex items-center justify-between mb-4">
        <p className="text-sm text-gray-600 text-center mb-8">
          Note: 1 Credits = 25 Messages
        </p>

        {/* Credits Left Badge */}
        <span className="bg-blue-100 text-blue-800 text-xs font-semibold px-2.5 py-0.5 rounded">
          Messages Left: {messageLimit}
        </span>
      </div>

        <div className="h-96 overflow-y-auto mb-4 bg-gray-100 p-4 rounded-lg">
          {messages.map((message, index) => (
            <div key={index} className={`mb-4 ${message.from === "bot" ? "text-left" : "text-right"}`}>
              <div className={`p-3 rounded-lg inline-block whitespace-pre-line ${message.from === "bot" ? "bg-purple-100 text-gray-800" : "bg-blue-500 text-white"}`}>
                <div>
                  {message.text === "loading" ? (
                    <Loader />
                  ) : (
                    <div dangerouslySetInnerHTML={{ __html: message.text }} />
                  )}
                </div>
              </div>
              {message.from === "bot" && (
                <button
                  className="ml-2 text-gray-600 hover:text-gray-900"
                  onClick={() => handleCopy(message.text)}
                  title="Copy to clipboard"
                >
                  <FaCopy />
                </button>
              )}
            </div>
          ))}
        </div>

        <div className="flex items-center mb-2">
          <textarea
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Type your question here..."
            className="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-600"
          />
          <button
            onClick={handleSend}
            className={`${input.trim() ? "bg-purple-600" : "bg-gray-400"} text-white p-3 rounded-full ml-2`}
            disabled={!input.trim() || loading}
          >
            <FaPaperPlane />
          </button>
          <button
            onClick={handleVoice}
            className={`p-3 rounded-full ml-2 ${recording ? "bg-red-600" : "bg-green-600"} text-white`}
            title="Record Voice"
          >
            <FaMicrophone />
          </button>
          {messages.length > 1 ? (
            <button
              onClick={handleClear}
              className="bg-purple-600 text-white p-3 rounded-full ml-2"
            >
              Clear
            </button>
          ) : (
            <button className="bg-gray-400 text-white p-3 rounded-full ml-2" disabled>
              Clear
            </button>
          )}
        </div>

        <p className="text-sm text-gray-600 text-center mt-4">
          Please note that AI Teacher's Assistant can make mistakes. Check important info.
        </p>
      </motion.div>
      <Feedback tool={{"name":"Teacher Assistant"}} />

      <motion.div className="max-w-5xl w-full bg-white shadow-lg rounded-lg p-8 mt-8"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: 'easeOut' }}
          viewport={{ once: true, amount: 0.2 }}>
        <h2 className="text-2xl font-bold mb-4">What is an AI Teacher's Assistant?</h2>
        <p className="text-gray-700 mb-4">
          The AI Teacher's Assistant is an advanced tool designed to help teachers with everyday tasks such as organizing duties, managing reminders, creating schedules, and setting priorities. By streamlining these activities, it allows educators to focus more on teaching and less on administrative work.
        </p>

        <h3 className="text-xl font-semibold mb-2">Why Use an AI Teacher's Assistant?</h3>
        <table className="min-w-full bg-white border-collapse mb-4">
          <thead>
            <tr>
              <th className="py-3 px-5 bg-gray-100 border-b text-left text-sm font-semibold text-gray-800">Benefit</th>
              <th className="py-3 px-5 bg-gray-100 border-b text-left text-sm font-semibold text-gray-800">Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="py-4 px-5 border-b text-gray-700 font-bold">Time-Saving</td>
              <td className="py-4 px-5 border-b text-gray-700">
                Automate tasks like setting reminders and managing schedules to save valuable time.
              </td>
            </tr>
            <tr>
              <td className="py-4 px-5 border-b text-gray-700 font-bold">Task Management</td>
              <td className="py-4 px-5 border-b text-gray-700">
                Keep track of responsibilities and prioritize tasks more effectively.
              </td>
            </tr>
            <tr>
              <td className="py-4 px-5 border-b text-gray-700 font-bold">Boost Productivity</td>
              <td className="py-4 px-5 border-b text-gray-700">
                Streamline lesson planning, administrative tasks, and classroom activities.
              </td>
            </tr>
            <tr>
              <td className="py-4 px-5 border-b text-gray-700 font-bold">Organized Workflow</td>
              <td className="py-4 px-5 border-b text-gray-700">
                Ensure you stay on top of daily duties with efficient scheduling and task management tools.
              </td>
            </tr>
            <tr>
              <td className="py-4 px-5 border-b text-gray-700 font-bold">Accessibility</td>
              <td className="py-4 px-5 border-b text-gray-700">
                Available anytime to assist with planning, organization, and reminders.
              </td>
            </tr>
          </tbody>
        </table>

        <h3 className="text-xl font-semibold mb-2">Why Choose StudentAITools for Your Teaching Needs?</h3>
        <p className="text-gray-700 mb-4">
          StudentAITools' AI Teacher's Assistant provides reliable, fast, and intelligent support for teachers. Whether it's helping with scheduling or creating lesson plans, our AI-powered tool ensures that your teaching tasks are managed efficiently, allowing you to focus on what matters most – your students.
        </p>

        <h3 className="text-xl font-semibold mb-2">How to Use Our AI Teacher's Assistant Tool</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 my-6">
          {/* Step 1 Card */}
          <div className="bg-white shadow-lg rounded-lg p-6 text-center flex flex-col items-center">
            <FaClipboardList className="h-12 w-12 text-blue-500 mb-4" />
            <h3 className="font-bold text-lg mb-2">Step 1</h3>
            <p className="text-gray-600">Enter your tasks, reminders, or duties for the day.</p>
          </div>

          {/* Step 2 Card */}
          <div className="bg-white shadow-lg rounded-lg p-6 text-center flex flex-col items-center">
            <FaClock className="h-12 w-12 text-green-500 mb-4" />
            <h3 className="font-bold text-lg mb-2">Step 2</h3>
            <p className="text-gray-600">Set deadlines, reminders, and manage your schedule efficiently.</p>
          </div>

          {/* Step 3 Card */}
          <div className="bg-white shadow-lg rounded-lg p-6 text-center flex flex-col items-center">
            <FaTasks className="h-12 w-12 text-purple-500 mb-4" />
            <h3 className="font-bold text-lg mb-2">Step 3</h3>
            <p className="text-gray-600">Organize and prioritize your tasks for the day or week.</p>
          </div>

          {/* Step 4 Card */}
          <div className="bg-white shadow-lg rounded-lg p-6 text-center flex flex-col items-center">
            <FaCheckCircle className="h-12 w-12 text-red-500 mb-4" />
            <h3 className="font-bold text-lg mb-2">Step 4</h3>
            <p className="text-gray-600">Check off completed tasks and track your progress.</p>
          </div>
        </div>

        <h3 className="text-xl font-semibold mb-2">Tips for Best Results</h3>
        <ul className="list-disc list-inside text-gray-700 mb-4">
          <li>Set realistic deadlines and reminders to stay on top of your tasks.</li>
          <li>Regularly update and review your task list for maximum productivity.</li>
          <li>Use the scheduling feature to plan lessons, meetings, and personal tasks.</li>
        </ul>

        <h3 className="text-xl font-semibold mb-2">Who Can Benefit from This AI Teacher's Assistant Tool?</h3>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 my-6">
          {/* Teachers */}
          <div className="bg-white shadow-lg rounded-lg p-6 text-center flex flex-col items-center">
            <FaChalkboardTeacher className="h-12 w-12 text-blue-500 mb-4" />
            <h3 className="font-bold text-lg mb-2">Teachers</h3>
            <p className="text-gray-600">
              Ideal for teachers who need to streamline administrative duties, plan lessons, and stay organized.
            </p>
          </div>

          {/* School Administrators */}
          <div className="bg-white shadow-lg rounded-lg p-6 text-center flex flex-col items-center">
            <FaUserTie className="h-12 w-12 text-green-500 mb-4" />
            <h3 className="font-bold text-lg mb-2">School Administrators</h3>
            <p className="text-gray-600">
              Great for administrators who need to manage tasks, meetings, and schedules efficiently.
            </p>
          </div>

          {/* Teaching Assistants */}
          <div className="bg-white shadow-lg rounded-lg p-6 text-center flex flex-col items-center">
            <FaUser className="h-12 w-12 text-purple-500 mb-4" />
            <h3 className="font-bold text-lg mb-2">Teaching Assistants</h3>
            <p className="text-gray-600">
              Helpful for assistants who need to support teachers and ensure that all tasks are completed on time.
            </p>
          </div>
        </div>

        <h3 className="text-xl font-semibold mb-2">Best Free AI Teacher's Assistant Tools</h3>
        <ul className="list-disc list-inside mb-4 text-gray-700">
          <li><strong>TeacherAide:</strong> Manage daily tasks, reminders, and schedules efficiently.</li>
          <li><strong>Classroom Assistant:</strong> Help streamline classroom activities and task management.</li>
          <li><strong>Task Scheduler:</strong> Plan and track tasks, reminders, and meetings with ease.</li>
        </ul>

        <h3 className="text-xl font-semibold mb-2">Disclaimer</h3>
        <p className="text-gray-700">
          The AI Teacher's Assistant aims to enhance productivity and task management for educators. However, we encourage users to verify all generated schedules and reminders to ensure they meet personal and professional needs. Use the tool as a guide and adapt it to your unique workflow.
        </p>
      </motion.div>
      
      <NewsletterCard/>
        
        {user && (
            <BuyCreditsButton />
        )}

    </div>
      <Footer />
    <LoginModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
};

export default TeacherAssistant;