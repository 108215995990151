import React, { useState, useEffect } from "react";
import { paraphraseText } from "../openaiService"; // Updated service for paraphrasing
import { getDatabase, ref, push, set, get, update } from "firebase/database";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { extractTextFromPDF, extractTextFromDocOrDocx } from "../fileService"; // Import the utility to extract text from files
import BuyCreditsButton from './BuyCreditsButton'; // Import the BuyCreditsButton component
import { FaFileUpload, FaClipboard, FaKeyboard, FaListAlt, FaSyncAlt, FaPen } from "react-icons/fa";
import Footer from "./Footer";
import LoginModal from "./LoginModal";
import Feedback from "./Feedback";
import NewsletterCard from "./NewsletterCard";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { motion } from 'framer-motion';

const MAX_TOKENS = 2000; // OpenAI max token limit for paraphrasing

const Quillbot = () => {
  const [inputText, setInputText] = useState("");
  const [paraphrasedText, setParaphrasedText] = useState("");
  const [wordCount, setWordCount] = useState(100); // Default word count
  const [selectedMode, setSelectedMode] = useState("Smooth"); // Default paraphrasing mode
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [viewMode, setViewMode] = useState(false); // To hide controls in view mode
  const [paraphraseLimit, setParaphraseLimit] = useState(2); // Free limit for unsubscribed users
  const [isSubscribed, setIsSubscribed] = useState(false); // Subscription status
  const [isModalOpen, setModalOpen] = useState(false);
  const [file, setFile] = useState(null); // For handling file uploads
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const location = useLocation(); // For accessing passed state
  const database = getDatabase();

  const paraphrasingModes = [
    "Smooth", "Reworder", "Formal", "Simple", "Fluency", "Creative",
    "Professional", "Natural", "Improver", "Academic", "Expand", "Shorten"
  ];
  var tool= { name: "Quillbot"}


  useEffect(() => {
    if (user) {
      const fetchUserData = async () => {
        const userRef = ref(database, `users/${user.uid}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const userData = snapshot.val();
          setIsSubscribed(userData.subscriptionStatus === "subscribed");
          if (userData.paraphraseLimit !== undefined) {
            setParaphraseLimit(userData.paraphraseLimit);
          } else {
            await update(userRef, { paraphraseLimit: 2 }); // Initialize limit
          }
        }
      };
      fetchUserData();
    }

    // If paraphrased text is passed from Documents, enable view mode
    if (location.state?.paraphrasedText) {
      setParaphrasedText(location.state.paraphrasedText);
      setViewMode(true);
    }else{
      setParaphrasedText('');
      setViewMode(false);
    }
  }, [user, location]);

  // Handle file uploads and extract text from files
  const handleFileUpload = async (e) => {
    const uploadedFile = e.target.files[0];
    setFile(uploadedFile);
    let extractedText = "";

    const fileExtension = uploadedFile.name.split('.').pop().toLowerCase();

    if (fileExtension === 'pdf') {
      extractedText = await extractTextFromPDF(uploadedFile);
    } else if (['doc', 'docx'].includes(fileExtension)) {
      extractedText = await extractTextFromDocOrDocx(uploadedFile);
    } else {
      setError("Unsupported file type. Please upload a .pdf, .doc, or .docx file.");
      return;
    }

    setInputText(extractedText);
  };

  // Handle paraphrasing
  const handleParaphrase = async (e) => {
    e.preventDefault();
    if (!user) {
      setModalOpen(true);
      return;
    }
    setError("");
    setParaphrasedText("");
    setLoading(true);

    if (!inputText.trim()) {
      setError("Please enter some text or upload a file to paraphrase.");
      setLoading(false);
      return;
    }

    const pageCount = Math.ceil(inputText.split(/\s+/).length / 500); // Estimate 500 words per page

    if (paraphraseLimit < pageCount) {
      toast.error(
        <span>
          Buy credits for creating more paraphrased text.
        </span>
      );
      setLoading(false);
      return;
    }

    try {
      const result = await paraphraseText(inputText, wordCount, selectedMode);
      setParaphrasedText(result);

      // Save the paraphrased text to Firebase under the logged-in user's uid
      if (user) {
        const paraphraseRef = push(ref(database, `summaries/${user.uid}`));
        await set(paraphraseRef, {
          originalText: inputText,
          summary: result,
          wordCount: wordCount,
          paraphrasingMode: selectedMode,
          createdAt: new Date().toISOString(),
          tool: "Quillbot",
        });

        toast.success("Saved to Documents!");

        // Deduct from the free limit if not subscribed
        if (!isSubscribed) {
          const newLimit = paraphraseLimit - pageCount;
          setParaphraseLimit(newLimit);
          const userRef = ref(database, `users/${user.uid}`);
          await update(userRef, { paraphraseLimit: newLimit });
        }
      }
    } catch (error) {
      setError("There was an issue with paraphrasing. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Handle word count change from slider or input box
  const handleWordCountChange = (value) => {
    const newValue = Math.min(value, MAX_TOKENS);
    setWordCount(newValue);
  };
  const handleDownload = () => {
    if (!paraphrasedText) {
      toast.error("No content to download!");  // Show error if there's no paraphrasedText generated
      return;
    }
  
    const blob = new Blob([paraphrasedText], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    
    const link = document.createElement("a");
    link.href = url;
    link.download = `paraphrasedText.txt`;  // Filename based on the topic
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleCopy = () => {
    if (paraphrasedText) {
      navigator.clipboard.writeText(paraphrasedText)
        .then(() => {
          toast.success("Text copied to clipboard!");
        })
        .catch((error) => {
          console.error("Error copying text: ", error);
          toast.error("Failed to copy text.");
        });
    } else {
      toast.error("No text to copy!");
    }
  };
  

  return (
    <>
      <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">

      <div className="w-full max-w-5xl flex justify-between items-center mb-4">
          {viewMode && (
            <button
              onClick={() => navigate("/documents")}
              className="text-blue-600 hover:underline"
            >
              &larr; Back to Documents
            </button>
          )}
          
          {user && (
          <div className="text-right">
            <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
              Credit Left: {paraphraseLimit}
            </span>
          </div>
          )}
      </div>

        {/* Main Header */}
        <h1 className="text-3xl font-bold text-center mb-4">AI Paraphrasing Tool</h1>
        <p className="text-lg text-gray-600 text-center mb-8">
          Effortlessly Paraphrase, Enhance and Perfect Your Text
        </p>

        {error && <p className="text-red-500 text-center mb-4">{error}</p>}

        {/* Main tool container with side-by-side layout */}
        <motion.div className="bg-white shadow-lg rounded-lg p-8 max-w-5xl w-full flex flex-col lg:flex-row"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, ease: 'easeOut' }}
        viewport={{ once: true, amount: 0.2 }}>
          {/* Original Version input */}

          {!viewMode && (
            <div className="w-full lg:w-1/2 pr-4 lg:border-r mb-4 lg:mb-0">
              <h2 className="text-lg font-bold mb-4">Original Version</h2>
      
              <form onSubmit={handleParaphrase}>
                <textarea
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                  className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
                  rows="10"
                  placeholder="Paste your text here..."
                  required
                ></textarea>
      
                <div className="flex justify-between items-center mt-4">
                  {/* FaFileUpload Icon (Left of Paraphrasing Mode Selection) */}
                    <label className="group cursor-pointer flex items-center">
                      <FaFileUpload className="text-gray-500 hover:text-gray-700 h-5 w-5 mr-2" />
                      Upload Doc
                      {/* File Input Hidden */}
                      <input
                        type="file"
                        accept=".doc,.docx,.pdf"
                        onChange={handleFileUpload}
                        className="hidden"
                      />
                    </label>
      
                    {/* Paraphrasing Mode Selection */}
                    <select
                      value={selectedMode}
                      onChange={(e) => setSelectedMode(e.target.value)}
                      className="ml-2 px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
                    >
                      {paraphrasingModes.map((mode) => (
                        <option key={mode} value={mode}>
                          {mode}
                        </option>
                      ))}
                    </select>
      
                  {/* Word Count Display */}
                  {/* <span className="text-sm text-gray-600">{inputText.length} / 750 words</span> */}
                </div>
      
                {/* Paraphrase Button */}
                <button type="submit" className="w-full mt-4 bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition duration-300 flex justify-center items-center" disabled={loading}>
                  {loading ? (
                    <div className="flex items-center space-x-2">
                      <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      <span>Paraphrasing...</span>
                    </div>
                  ) : "Paraphrase"}
                </button>
              </form>
            </div>
          )}

          {/* Paraphrased Version result */}
          <div className={viewMode ? "w-full pl-4" : "w-full lg:w-1/2 pl-4"}>
            <h2 className="text-lg font-bold mb-4">Paraphrased Version</h2>
    
            {/* Paraphrased text output */}
            <div className="bg-gray-100 p-4 rounded-lg h-72 lg:h-80 relative overflow-auto">
              {/* Paraphrased text */}
              <p className="text-gray-700 whitespace-pre-wrap">
                {paraphrasedText || "Your paraphrased text will appear here..."}
              </p>
            </div>
            {/* Buttons below the result */}
            <div className="flex justify-between mt-4">
              {/* Download Button */}
              <button
                onClick={handleDownload}
                className="bg-gray-600 text-white px-4 py-2 rounded-lg hover:bg-gray-700 transition duration-300"
              >
                Download
              </button>

              {/* Copy to Clipboard Button */}
              <button
                onClick={handleCopy}
                className="text-gray-500 hover:text-gray-700"
                title="Copy to Clipboard"
              >
                <FaClipboard className="text-xl text-gray-600 hover:text-blue-600" />
              </button>
            </div>

          </div>
        </motion.div>
      <Feedback tool={{"name":"Quillbot"}} />
    
        {/* Tool Description Section */}
        {!viewMode && (
          <motion.div className="max-w-5xl w-full bg-white shadow-lg rounded-lg p-8 mt-8"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: 'easeOut' }}
          viewport={{ once: true, amount: 0.2 }}>
            <h2 className="text-2xl font-bold mb-4">What is a AI Paraphrasing Tool?</h2>
            <p className="text-gray-700 mb-4">
              The Paraphrasing Tool is an advanced AI-powered application that helps you rewrite and enhance your text. It rephrases your original content while retaining its core meaning, offering improved clarity and uniqueness. Whether you're a student, writer, or professional, our tool is designed to streamline your content creation process.
            </p>
            <h3 className="text-xl font-semibold mb-2">Available Paraphrasing Modes:</h3>
            <ol style={{ listStyleType: "number" }} className="list-disc list-inside mb-4 text-gray-700">
              <li><strong>Smooth:</strong> Focuses on making the text flow more naturally, perfect for improving readability and reducing awkward phrasing.</li>
              <li><strong>Reworder:</strong> Primarily replaces words with synonyms and restructures sentences while keeping the meaning intact.</li>
              <li><strong>Formal:</strong> Elevates the tone of the text to a more professional or academic level, making it suitable for business or educational purposes.</li>
              <li><strong>Simple:</strong> Simplifies the language and sentence structure to make the content easy to understand, ideal for wider audiences.</li>
              <li><strong>Fluency:</strong> Enhances the text by ensuring smooth transitions between ideas, improving overall clarity and cohesion.</li>
              <li><strong>Creative:</strong> Adds a unique twist to the content, incorporating more imaginative wording while retaining the original meaning.</li>
              <li><strong>Professional:</strong> Adjusts the tone and style to fit a professional or corporate environment, making the content suitable for work-related communication.</li>
              <li><strong>Natural:</strong> Paraphrases the text in a way that sounds conversational, like natural human speech, perfect for blogs or casual writing.</li>
              <li><strong>Improver:</strong> Enhances the overall quality of the text by improving sentence structure and adding more impactful language.</li>
              <li><strong>Academic:</strong> Tailors the content for an academic audience, ensuring it is structured and phrased appropriately for research papers or essays.</li>
              <li><strong>Expand:</strong> Increases the length of the text by adding more details or explanations, without deviating from the original meaning.</li>
              <li><strong>Shorten:</strong> Condenses the content, focusing on brevity and clarity while removing unnecessary words or information.</li>
            </ol>
            <h3 className="text-xl font-semibold mb-2">Available File Upload Options:</h3>
            <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 p-4">
            {/* DOC File Type */}
            <div className="bg-white shadow-md rounded-lg p-6 flex flex-col items-center">
              <img src="/images/doc-icon.png" alt="DOC file" className="w-16 h-16 mb-4" />
              <h3 className="text-lg font-semibold mb-2">DOC</h3>
              <p className="text-gray-600 text-center">
                Effortlessly paraphrase your DOC files for free using our AI tool. Perfect for rewriting essays, reports, and other Word documents while maintaining the original formatting.
              </p>
            </div>

            {/* DOCX File Type */}
            <div className="bg-white shadow-md rounded-lg p-6 flex flex-col items-center">
              <img src="/images/docx-icon.png" alt="DOCX file" className="w-16 h-16 mb-4" />
              <h3 className="text-lg font-semibold mb-2">DOCX</h3>
              <p className="text-gray-600 text-center">
                Paraphrase DOCX files quickly and efficiently with our tool, ensuring your documents are reformulated with professional-grade quality. Ideal for academic papers and business documents.
              </p>
            </div>

            {/* PDF File Type */}
            <div className="bg-white shadow-md rounded-lg p-6 flex flex-col items-center">
              <img src="/images/pdf-icon.png" alt="PDF file" className="w-16 h-16 mb-4" />
              <h3 className="text-lg font-semibold mb-2">PDF</h3>
              <p className="text-gray-600 text-center">
                Easily paraphrase PDF documents for free while preserving the layout and structure. Our tool can handle a wide range of PDFs, from academic articles to business presentations, giving you unique content effortlessly.
              </p>
            </div>
          </div>

      
            <h3 className="text-xl font-semibold mb-2">Why Use AI Paraphrasing Tool ?</h3>
            <table className="min-w-full bg-white border rounded-lg mb-4">
              <thead>
                <tr>
                  <th className="text-left px-6 py-4 border-b font-semibold text-gray-700">Benefit</th>
                  <th className="text-left px-6 py-4 border-b font-semibold text-gray-700">Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="px-6 py-4 border-b text-gray-700 font-bold">Avoid Plagiarism</td>
                  <td className="px-6 py-4 border-b text-gray-700">Rephrase content to ensure originality and avoid plagiarism.</td>
                </tr>
                <tr>
                  <td className="px-6 py-4 border-b text-gray-700 font-bold">Improve Clarity</td>
                  <td className="px-6 py-4 border-b text-gray-700">Make your text more understandable by improving structure and coherence.</td>
                </tr>
                <tr>
                  <td className="px-6 py-4 border-b text-gray-700 font-bold">Save Time</td>
                  <td className="px-6 py-4 border-b text-gray-700">Quickly generate unique content, saving you time on manual rewriting.</td>
                </tr>
                <tr>
                  <td className="px-6 py-4 border-b text-gray-700 font-bold">Enhance Vocabulary</td>
                  <td className="px-6 py-4 border-b text-gray-700">Introduce new vocabulary and fresh phrasing to enrich your content.</td>
                </tr>
                <tr>
                  <td className="px-6 py-4 border-b text-gray-700 font-bold">SEO Optimization</td>
                  <td className="px-6 py-4 border-b text-gray-700">Produce unique content that boosts your search engine rankings.</td>
                </tr>
              </tbody>
            </table>

            <h3 className="text-xl font-semibold mb-2">Why Choose StudentAITools?</h3>
            <p className="text-gray-700 mb-4">
              StudentAITools stands out for its cutting-edge AI technology, ease of use, and accuracy. Our AI Paraphrasing Tool provides high-quality rephrased content tailored to your needs. We offer multiple paraphrasing modes, so whether you need light rewording or extensive rewriting, you can find the perfect solution here. Plus, our tool is free to use, and no registration is required.
            </p>

            <h3 className="text-xl font-semibold mb-2">How to Use Our AI Paraphrasing Tool</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
              {/* Step 1 */}
              <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
                <div className="flex-shrink-0 h-12 w-12 bg-blue-100 rounded-full flex items-center justify-center">
                  <FaKeyboard className="text-blue-600" /> {/* Icon for Text Input */}
                </div>
                <div className="ml-4">
                  <h3 className="text-lg font-semibold text-gray-800">Step 1</h3>
                  <p className="text-gray-600">
                    Enter the text you want to paraphrase in the provided input field OR upload the document that needs to be paraphrased.
                  </p>
                </div>
              </div>

              {/* Step 2 */}
              <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
                <div className="flex-shrink-0 h-12 w-12 bg-green-100 rounded-full flex items-center justify-center">
                  <FaListAlt className="text-green-600" /> {/* Icon for Mode Selection */}
                </div>
                <div className="ml-4">
                  <h3 className="text-lg font-semibold text-gray-800">Step 2</h3>
                  <p className="text-gray-600">
                    Select from 12 different paraphrasing modes.
                  </p>
                </div>
              </div>

              {/* Step 3 */}
              <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
                <div className="flex-shrink-0 h-12 w-12 bg-yellow-100 rounded-full flex items-center justify-center">
                  <FaSyncAlt className="text-yellow-600" /> {/* Icon for Paraphrase Action */}
                </div>
                <div className="ml-4">
                  <h3 className="text-lg font-semibold text-gray-800">Step 3</h3>
                  <p className="text-gray-600">
                    Click the "Paraphrase" button to generate the rephrased content.
                  </p>
                </div>
              </div>

              {/* Step 4 */}
              <div className="flex items-center p-4 bg-white shadow-lg rounded-lg">
                <div className="flex-shrink-0 h-12 w-12 bg-red-100 rounded-full flex items-center justify-center">
                  <FaPen className="text-red-600" /> {/* Icon for Review and Edit */}
                </div>
                <div className="ml-4">
                  <h3 className="text-lg font-semibold text-gray-800">Step 4</h3>
                  <p className="text-gray-600">
                    Review and edit the output to suit your style and tone.
                  </p>
                </div>
              </div>
            </div>

            <h3 className="text-xl font-semibold mb-2">Tips for Best Results</h3>
            <ul className="list-disc list-inside text-gray-700 mb-4">
              <li>Provide clear, concise input text for the best paraphrasing results.</li>
              <li>Choose the appropriate paraphrasing mode based on your desired level of rewriting.</li>
              <li>Review the output and make any necessary adjustments to match your personal writing style.</li>
            </ul>

            <h3 className="text-xl font-semibold mb-2">Who Can Benefit from This AI Paraphrasing Tool?</h3>
            <ul className="list-disc list-inside mb-4 text-gray-700">
              <li>Students who need help rephrasing essays or academic papers.</li>
              <li>Writers looking to refresh their content with new wording.</li>
              <li>Professionals aiming to rewrite reports or business documents without altering their meaning.</li>
              <li>Non-native speakers seeking to improve the clarity and fluency of their written English.</li>
            </ul>

            <h3 className="text-xl font-semibold mb-2">Best Free AI Paraphrasing Tools</h3>
            <ul className="list-disc list-inside mb-4 text-gray-700">
              <li><strong>QuillBot:</strong> A versatile paraphrasing tool known for its precision and multiple rewriting modes.</li>
              <li><strong>Spinbot:</strong> Simple and fast paraphrasing with no word limit, ideal for quick content rewrites.</li>
              <li><strong>Grammarly:</strong> Known for its grammar-checking capabilities, Grammarly also offers effective paraphrasing suggestions.</li>
              <li><strong>Paraphrase Online:</strong> A user-friendly tool that rephrases sentences while maintaining the original meaning.</li>
            </ul>

            <h3 className="text-xl font-semibold mb-2">Disclaimer</h3>
            <p className="text-gray-700">
              Our AI Paraphrasing Tool aims for high accuracy. However, achieving perfection is still challenging. Please note: the predictions are for demonstration purposes only. Do not rely on them for real-world applications. Use caution, as we are not liable for any damages incurred. We strongly recommend using human oversight when using our products.
            </p>
          </motion.div>
        )}
        <NewsletterCard/>
        {user && (
          <BuyCreditsButton />
        )}
      </div>

      {!viewMode && (
        <Footer />
      )}

    <LoginModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
  </>
  );  
};

export default Quillbot;