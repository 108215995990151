import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { generateResponse, transcribeVoice } from "../openaiService";
import Loader from "./Loader";
import { getDatabase, ref, get, update } from "firebase/database";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Feedback from "./Feedback";
import NewsletterCard from "./NewsletterCard";
import { FaMicrophone, FaPaperPlane, FaCopy, FaRobot, FaClipboard, FaKeyboard, FaPaste, FaLightbulb,FaClipboardList, FaUser, FaGraduationCap, FaChalkboardTeacher, FaPenFancy, FaBlog, FaSearch} from "react-icons/fa";
import Footer from "./Footer";
import LoginModal from "./LoginModal";
import BuyCreditsButton from './BuyCreditsButton'; 
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';

const HomeworkHelper = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [recording, setRecording] = useState(false);
  const [messageLimit, setMessageLimit] = useState(50); // Default message limit
  const [messageCount, setMessageCount] = useState(0);
  const [user] = useAuthState(auth); // Firebase auth for getting user details
  const [isModalOpen, setModalOpen] = useState(false);

  // Initial prompt from the chatbot
  const initialPrompt = "📚 Hi there! Welcome to your AI Homework Helper! 😊 I'm here to assist you with your homework. Just type in your question or topic, and I'll guide you step-by-step.\n\nHere's what I can help you with:\n\n• 📝 Solve problems • 🔬 Break down concepts • 📚 Improve understanding • 💡 Offer tips and guidance • 🧠 Provide study strategies • 📅 Help with project planning\n\nWhat's your current homework challenge?";

  // Set the initial message and fetch message limit on mount
  useEffect(() => {
    if (user) {
      fetchUserCredits(); // Fetch message limit from Firebase
      loadMessagesFromLocalStorage(); // Load previous chat from localStorage if any
    } else {
      setMessages([{ from: "bot", text: initialPrompt }]);
    }
  }, [user]);

  // Fetch message limit from Firebase for the logged-in user
  const fetchUserCredits = async () => {
    const db = getDatabase();
    const userRef = ref(db, `users/${user.uid}`);
    const snapshot = await get(userRef);
    if (snapshot.exists()) {
      const userData = snapshot.val();
      setMessageLimit(userData.homework_helper_message_limit || 50); // Default 50 if not set
      setMessageCount(userData.homework_helper_message_count || 0);
    } else {
      // If no data exists, set default limit in Firebase
      await update(userRef, { homework_helper_message_limit: 50 });
      setMessageLimit(50);
      
      await update(userRef, { homework_helper_message_count: 0 });
      setMessageCount(0);
    }
  };

  // Load messages from localStorage if they exist
  const loadMessagesFromLocalStorage = () => {
    const storedMessages = localStorage.getItem(`studentChat_${user.uid}`);
    if (storedMessages) {
      setMessages(JSON.parse(storedMessages));
    } else {
      setMessages([{ from: "bot", text: initialPrompt }]);
    }
  };

  // Save messages to localStorage
  const saveMessagesToLocalStorage = (newMessages) => {
    localStorage.setItem(`studentChat_${user.uid}`, JSON.stringify(newMessages));
  };

  // Handle the "Send" button click
  const handleSend = async () => {
    try {
      if (!user) {
        setModalOpen(true);
        return;
      }

      if (!input.trim()) return;

      // Check if the message limit has been reached
      if (messageLimit <= 0) {
        toast.error("You have reached the free limit of homework helper. Buy credits for more messages!");
        return;
      }

      const newMessages = [...messages, { from: "user", text: input }];
      setMessages(newMessages);
      saveMessagesToLocalStorage(newMessages); // Save the conversation to localStorage
      setInput(""); // Clear the input box
      setLoading(true);

      // Add the loader below the user's message
      const loaderMessages = [...newMessages, { from: "bot", text: "loading" }];
      setMessages(loaderMessages);

      // Check if it's an educational question
      const response = await generateResponse(input,'student');

      // Remove the loader once the response is ready
      const filteredMessages = loaderMessages.filter(message => message.text !== "loading");

      if (response.isEducational) {
        const updatedMessages = [...filteredMessages, { from: "bot", text: response.answer }];
        setMessages(updatedMessages);
        saveMessagesToLocalStorage(updatedMessages); // Save the updated conversation
      } else {
        const updatedMessages = [...filteredMessages, { from: "bot", text: "I can’t help you with this, Please ask me only educational questions" }];
        setMessages(updatedMessages);
        saveMessagesToLocalStorage(updatedMessages); // Save the updated conversation
      }

      // Deduct from message limit in Firebase
      deductMessageLimit();

      setLoading(false);
    }
    catch (error) {
      toast.error("There was an issue generating response from Chatbot. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Deduct 1 from the message limit in Firebase
  const deductMessageLimit = async () => {
    const db = getDatabase();
    const userRef = ref(db, `users/${user.uid}`);
    const newLimit = messageLimit - 1;
    setMessageLimit(newLimit);
    await update(userRef, { homework_helper_message_limit: newLimit });

    const newCount = messageCount + 1;
    setMessageCount(newCount);
    await update(userRef, { homework_helper_message_count: newCount });
  };

    // Handle voice transcription
    const handleVoice = async () => {
      setRecording(true);
      const transcription = await transcribeVoice(); // Transcribing voice using OpenAI
      setInput(transcription); // Set transcription to input box
      setRecording(false);
    };


  // Handle Clear Button (clears localStorage and resets chat)
  const handleClear = () => {
    setMessages([{ from: "bot", text: initialPrompt }]);
    localStorage.removeItem(`studentChat_${user.uid}`); // Clear the stored conversation
  };

  // Handle keypress event on the input (Enter key)
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey && input.trim()) {
      e.preventDefault();
      handleSend();
    }
  };

  // Copy text to clipboard
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard!");
  };

  return (
    <>
    <Helmet>
        <title>AI Worksheet Generator - Generate Custom Worksheets | StudentAITools</title>
        <meta
          name="description"
          content="Create custom worksheets instantly with our AI Worksheet Generator tool. Perfect for teachers looking for efficient content generation for their students."
        />
      </Helmet>
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      {/* Main Header */}
      <h1 className="text-3xl font-bold text-center my-4">AI Homework Helper</h1>
        <p className="text-lg text-gray-600 text-center mb-8">
        Your AI-Powered Homework Assistant for Instant Study Help
      </p>

      <motion.div className="max-w-5xl mx-auto p-6 bg-white rounded-lg shadow-lg"
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, ease: 'easeOut' }}
      viewport={{ once: true, amount: 0.2 }}>
        <div className="flex items-center justify-between mb-4">
          <p className="text-sm text-gray-600 text-center mb-8">
          Note: 1 Credits = 25 Messages
        </p>

        {/* Credits Left Badge */}
        <span className="bg-blue-100 text-blue-800 text-xs font-semibold px-2.5 py-0.5 rounded">
          Messages Left: {messageLimit}
        </span>
      </div>

        <div className="h-96 overflow-y-auto mb-4 bg-gray-100 p-4 rounded-lg">
          {messages.map((message, index) => (
            <div key={index} className={`mb-4 ${message.from === "bot" ? "text-left" : "text-right"}`}>
              <div className={`p-3 rounded-lg inline-block whitespace-pre-line  ${message.from === "bot" ? "bg-purple-100 text-gray-800" : "bg-blue-500 text-white"}`}>
                <div>
                  {message.text === "loading" ? (
                    <Loader />
                  ) : (
                    <div dangerouslySetInnerHTML={{ __html: message.text }} />
                  )}
                </div>
              </div>
              {message.from === "bot" && (
                <button
                  className="ml-2 text-gray-600 hover:text-gray-900"
                  onClick={() => handleCopy(message.text)}
                  title="Copy to clipboard"
                >
                  <FaCopy />
                </button>
              )}
            </div>
          ))}
        </div>

        <div className="flex items-center mb-2">
          <textarea
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Type your question here..."
            className="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-600"
          />
          <button
            onClick={handleSend}
            className={`${input.trim() ? "bg-purple-600" : "bg-gray-400"} text-white p-3 rounded-full ml-2`}
            disabled={!input.trim() || loading}
          >
            <FaPaperPlane />
          </button>
          <button
            onClick={handleVoice}
            className={`p-3 rounded-full ml-2 ${recording ? "bg-red-600" : "bg-green-600"} text-white`}
            title="Record Voice"
          >
            <FaMicrophone />
          </button>
          {messages.length > 1 ? (
            <button
              onClick={handleClear}
              className="bg-purple-600 text-white p-3 rounded-full ml-2"
            >
              Clear
            </button>
          ) : (
            <button className="bg-gray-400 text-white p-3 rounded-full ml-2" disabled>
              Clear
            </button>
          )}
        </div>

        <p className="text-sm text-gray-600 text-center mt-4">
          Please note that AI Homework Helper can make mistakes. Check important info.
        </p>
      </motion.div>
      <Feedback tool={{"name":"Homework Helper"}} />
      <motion.div className="max-w-5xl w-full bg-white shadow-lg rounded-lg p-8 mt-8"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: 'easeOut' }}
          viewport={{ once: true, amount: 0.2 }}>
        <h2 className="text-2xl font-bold mb-4">What is an AI Homework Helper?</h2>
        <p className="text-gray-700 mb-4">
          The AI Homework Helper is an advanced tool designed to assist students and teachers with homework challenges by offering step-by-step solutions, breaking down complex topics, and providing tips for better study strategies. Whether you're a student seeking clarification or a teacher looking for quick solutions, this AI tool can make your homework tasks much easier and more efficient.
        </p>

        <h3 className="text-xl font-semibold mb-2">Why Use an AI Homework Helper?</h3>
        <table className="min-w-full bg-white border-collapse mb-4">
          <thead>
            <tr>
              <th className="py-3 px-5 bg-gray-100 border-b text-left text-sm font-semibold text-gray-800">Benefit</th>
              <th className="py-3 px-5 bg-gray-100 border-b text-left text-sm font-semibold text-gray-800">Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="py-4 px-5 border-b text-gray-700 font-bold">Save Time</td>
              <td className="py-4 px-5 border-b text-gray-700">
                Get quick answers to homework questions and save valuable study time.
              </td>
            </tr>
            <tr>
              <td className="py-4 px-5 border-b text-gray-700 font-bold">Improve Understanding</td>
              <td className="py-4 px-5 border-b text-gray-700">
                Break down complex topics into simple explanations to enhance understanding.
              </td>
            </tr>
            <tr>
              <td className="py-4 px-5 border-b text-gray-700 font-bold">Boost Productivity</td>
              <td className="py-4 px-5 border-b text-gray-700">
                With instant help from AI, focus on key concepts and complete homework faster.
              </td>
            </tr>
            <tr>
              <td className="py-4 px-5 border-b text-gray-700 font-bold">Step-by-Step Solutions</td>
              <td className="py-4 px-5 border-b text-gray-700">
                Receive detailed, step-by-step guidance on homework problems, from math to science.
              </td>
            </tr>
            <tr>
              <td className="py-4 px-5 border-b text-gray-700 font-bold">Accessible Anytime</td>
              <td className="py-4 px-5 border-b text-gray-700">
                Get help anytime, anywhere, without waiting for teachers or tutors.
              </td>
            </tr>
          </tbody>
        </table>

        <h3 className="text-xl font-semibold mb-2">Why Choose StudentAITools?</h3>
        <p className="text-gray-700 mb-4">
          At StudentAITools, our AI Homework Helper provides reliable, fast, and accurate assistance to both students and teachers. With AI-powered tools designed specifically for academic challenges, you can confidently tackle any homework or teaching task with ease.
        </p>

        <h3 className="text-xl font-semibold mb-2">How to Use Our AI Homework Helper Tool</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 my-6">
          {/* Step 1 Card */}
          <div className="bg-white shadow-lg rounded-lg p-6 text-center flex flex-col items-center">
            <FaPaste className="h-12 w-12 text-blue-500 mb-4" />
            <h3 className="font-bold text-lg mb-2">Step 1</h3>
            <p className="text-gray-600">Enter your homework question or topic.</p>
          </div>

          {/* Step 2 Card */}
          <div className="bg-white shadow-lg rounded-lg p-6 text-center flex flex-col items-center">
            <FaRobot className="h-12 w-12 text-green-500 mb-4" />
            <h3 className="font-bold text-lg mb-2">Step 2</h3>
            <p className="text-gray-600">Click "Send" and let AI analyze your query.</p>
          </div>

          {/* Step 3 Card */}
          <div className="bg-white shadow-lg rounded-lg p-6 text-center flex flex-col items-center">
            <FaLightbulb className="h-12 w-12 text-purple-500 mb-4" />
            <h3 className="font-bold text-lg mb-2">Step 3</h3>
            <p className="text-gray-600">Receive step-by-step guidance and answers.</p>
          </div>

          {/* Step 4 Card */}
          <div className="bg-white shadow-lg rounded-lg p-6 text-center flex flex-col items-center">
            <FaClipboardList className="h-12 w-12 text-red-500 mb-4" />
            <h3 className="font-bold text-lg mb-2">Step 4</h3>
            <p className="text-gray-600">Save, copy, or continue with the next task.</p>
          </div>
        </div>

        <h3 className="text-xl font-semibold mb-2">Tips for Best Results</h3>
        <ul className="list-disc list-inside text-gray-700 mb-4">
          <li>Ask specific questions for clear, concise answers.</li>
          <li>Use the tool for math problems, essay topics, and science questions.</li>
          <li>Try the voice input feature for faster interaction.</li>
        </ul>

        <h3 className="text-xl font-semibold mb-2">Who Can Benefit from This AI Homework Helper Tool?</h3>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 my-6">
          {/* Students */}
          <div className="bg-white shadow-lg rounded-lg p-6 text-center flex flex-col items-center">
            <FaGraduationCap className="h-12 w-12 text-blue-500 mb-4" />
            <h3 className="font-bold text-lg mb-2">Students</h3>
            <p className="text-gray-600">
              Perfect for students who need instant homework help or want to understand tough concepts more easily.
            </p>
          </div>

          {/* Teachers */}
          <div className="bg-white shadow-lg rounded-lg p-6 text-center flex flex-col items-center">
            <FaChalkboardTeacher className="h-12 w-12 text-green-500 mb-4" />
            <h3 className="font-bold text-lg mb-2">Teachers</h3>
            <p className="text-gray-600">
              Helpful for teachers to quickly generate answers, examples, and teaching strategies for classroom activities.
            </p>
          </div>

          {/* Parents */}
          <div className="bg-white shadow-lg rounded-lg p-6 text-center flex flex-col items-center">
            <FaUser className="h-12 w-12 text-purple-500 mb-4" />
            <h3 className="font-bold text-lg mb-2">Parents</h3>
            <p className="text-gray-600">
              Parents can use it to assist children with their homework and track progress more effectively.
            </p>
          </div>
        </div>

        <h3 className="text-xl font-semibold mb-2">Best Free AI Homework Helper Tools</h3>
        <ul className="list-disc list-inside mb-4 text-gray-700">
          <li><strong>StudentAITools:</strong> Offers free access to a powerful AI assistant for quick homework solutions.</li>
          <li><strong>Wolfram Alpha:</strong> Known for solving complex math and science problems efficiently.</li>
          <li><strong>Socratic by Google:</strong> AI-powered app for answering homework questions across various subjects.</li>
          <li><strong>Photomath:</strong> Helps students solve math problems by scanning and providing step-by-step solutions.</li>
        </ul>

        <h3 className="text-xl font-semibold mb-2">Disclaimer</h3>
        <p className="text-gray-700">
          Our AI Homework Helper aims to provide accurate and helpful guidance. However, users should verify the information provided and avoid using it as a sole source of information for critical tasks. AI tools may occasionally produce incorrect or incomplete answers.
        </p>
      </motion.div>
      <NewsletterCard/>
        
        {user && (
            <BuyCreditsButton />
        )}

    </div>
      <Footer />
    <LoginModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
};

export default HomeworkHelper;