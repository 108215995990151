// src/openaiService.js

import { auth } from './firebase'; // Import the auth instance

export const paraphraseText = async (text, wordCount, mode) => {
  // Get the current Firebase user
  try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('User not authenticated');
      }
      // Get the ID token from Firebase
      const idToken = await user.getIdToken();

      const response = await fetch('https://app.studentaitools.com/api/paraphrase', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}` // Pass Firebase token to backend
        },
        body: JSON.stringify({
          text,
          wordCount,
          mode
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch paraphrased text');
      }

      const data = await response.json();
      return data.paraphrasedText;
    }
    catch (error) {
      console.error("Error generating paraphrased text:", error);
      return "Error generating paraphrased text.";
    }
};

export const summarizeText = async (text, wordCount) => {
  // Get the current authenticated user
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User not authenticated');
  }

  // Get Firebase ID token
  const idToken = await user.getIdToken();

  const response = await fetch('https://app.studentaitools.com/api/summarize', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`, // Pass Firebase token to the backend
    },
    body: JSON.stringify({
      text,
      wordCount,
    }),
  });

  if (!response.ok) {
    throw new Error('Failed to fetch summary');
  }

  const data = await response.json();
  return data.summary;
};

  export const generateBulletPoints = async (question) => {
    // Get the current authenticated user
    const user = auth.currentUser;

    if (!user) {
      throw new Error('User not authenticated');
    }

    // Get the Firebase ID token
    const idToken = await user.getIdToken();

    const response = await fetch('https://app.studentaitools.com/api/generate-bullet-points', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`, // Pass Firebase token to backend
      },
      body: JSON.stringify({
        question,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to generate bullet points');
    }

    const data = await response.json();
    return data.bulletPoints; // The result contains HTML bullet points
  };

  export const generateLessonPlan = async (lessonTopic, focusingOn, gradeLevel, lessonStyle, lessonDuration, previousLesson) => {
    // Get the current authenticated user
    const user = auth.currentUser;
  
    if (!user) {
      throw new Error('User not authenticated');
    }
  
    // Get the Firebase ID token
    const idToken = await user.getIdToken();
  
    const response = await fetch('https://app.studentaitools.com/api/generate-lesson-plan', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`, // Pass Firebase token to backend
      },
      body: JSON.stringify({
        lessonTopic,
        focusingOn,
        gradeLevel,
        lessonStyle,
        lessonDuration,
        previousLesson,
      }),
    });
  
    if (!response.ok) {
      throw new Error('Failed to generate lesson plan');
    }
  
    const data = await response.json();
    return data.lessonPlan; // The result contains HTML formatted lesson plan
  };

  export const generateWorksheet = async (subject, gradeLevel, numQuestions) => {
    // Get the current authenticated user
    const user = auth.currentUser;
  
    if (!user) {
      throw new Error('User not authenticated');
    }
  
    // Get the Firebase ID token
    const idToken = await user.getIdToken();
  
    const response = await fetch('https://app.studentaitools.com/api/generate-worksheet', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`, // Pass Firebase token to backend
      },
      body: JSON.stringify({
        subject,
        gradeLevel,
        numQuestions,
      }),
    });
  
    if (!response.ok) {
      throw new Error('Failed to generate worksheet');
    }
  
    const data = await response.json();
    return data.worksheet; // The result contains HTML formatted worksheet
  };
  
  export const generateResponse = async (input, assistant_type) => {
    // Get the current authenticated user
    const user = auth.currentUser;
  
    if (!user) {
      throw new Error('User not authenticated');
    }
  
    // Get the Firebase ID token
    const idToken = await user.getIdToken();
  
    const response = await fetch('https://app.studentaitools.com/api/generate-response', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`, // Pass Firebase token to backend
      },
      body: JSON.stringify({
        input,
        assistant_type,
      }),
    });
  
    if (!response.ok) {
      throw new Error('Failed to generate response');
    }
  
    const data = await response.json();
    return data;
  };

  export const transcribeVoice = async () => {
    try {
      // Capture audio from the user's microphone
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
  
      // Store the audio data as chunks
      const audioChunks = [];
  
      // Start recording
      mediaRecorder.start();
  
      return new Promise((resolve, reject) => {
        mediaRecorder.ondataavailable = (event) => {
          audioChunks.push(event.data);
        };
  
        mediaRecorder.onstop = async () => {
          const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
  
          // Convert the Blob to a File for sending to backend
          const audioFile = new File([audioBlob], 'recording.wav', { type: 'audio/wav' });
  
          // Get the current authenticated user
          const user = auth.currentUser;
  
          if (!user) {
            throw new Error('User not authenticated');
          }
  
          // Get the Firebase ID token
          const idToken = await user.getIdToken();
  
          // Create form data to send to backend
          const formData = new FormData();
          formData.append('file', audioFile);
  
          try {
            // Send the audio file to the backend
            const response = await fetch('https://app.studentaitools.com/api/transcribe-voice', {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${idToken}`, // Pass Firebase token to backend
              },
              body: formData,
            });
  
            if (!response.ok) {
              throw new Error('Failed to transcribe voice');
            }
  
            const data = await response.json();
            resolve(data.transcription); // Transcription result
          } catch (error) {
            console.error('Error sending audio to backend', error);
            reject('Error: Could not transcribe audio');
          }
        };
  
        // Automatically stop recording after 10 seconds or when the user stops
        setTimeout(() => {
          mediaRecorder.stop();
        }, 10000); // Recording limit: 10 seconds
      });
    } catch (error) {
      console.error('Error accessing microphone', error);
      throw new Error('Could not access microphone');
    }
  };

  export const generateQuiz = async (userId, quizId, topic, difficulty, numQuestions, useCustomTopic, selectedGrade, selectedSubject, selectedTopic, questionType) => {
    // Get the current authenticated user
    const user = auth.currentUser;
  
    if (!user) {
      throw new Error('User not authenticated');
    }
  
    // Get the Firebase ID token
    const idToken = await user.getIdToken();
  
    try {
      // Send the quiz generation request to the backend
      const response = await fetch('https://app.studentaitools.com/api/generate-quiz', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`, // Pass Firebase token to backend
        },
        body: JSON.stringify({
          userId, 
          quizId,
          topic,
          difficulty,
          numQuestions,
          useCustomTopic,
          selectedGrade,
          selectedSubject,
          selectedTopic,
          questionType,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to generate quiz');
      }
  
      const data = await response.json();
      return data; // Returns the generated quiz
    } catch (error) {
      console.error('Error generating quiz:', error);
      throw new Error('An error occurred while generating the quiz.');
    }
  }; 

  export const solveMathQuestion = async (question) => {
    // Get the current authenticated user
    const user = auth.currentUser;
  
    if (!user) {
      throw new Error('User not authenticated');
    }
  
    // Get the Firebase ID token
    const idToken = await user.getIdToken();
  
    try {
      // Send the math question to the backend for solving
      const response = await fetch('https://app.studentaitools.com/api/solve-math-question', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`, // Pass Firebase token to backend
        },
        body: JSON.stringify({
          question,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to solve math question');
      }
  
      const data = await response.json();
      return data.solution; // Return the solution from the backend
    } catch (error) {
      console.error('Error solving math question:', error);
      throw new Error('An error occurred while solving the math question.');
    }
  };

  export const generatePodcastScript = async (userId, podcastId, topic, speakers) => {
    // Get the current authenticated user
    const user = auth.currentUser;
  
    if (!user) {
      throw new Error('User not authenticated');
    }
  
    // Get the Firebase ID token
    const idToken = await user.getIdToken();
  
    try {
      // Send the math question to the backend for solving
      const response = await fetch('https://app.studentaitools.com/api/generate-podcast', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`, // Pass Firebase token to backend
        },
        body: JSON.stringify({
          userId,
          podcastId,
          topic, 
          speakers
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to solve math question');
      }
  
      const data = await response.json();
      return data.solution; // Return the solution from the backend
    } catch (error) {
      console.error('Error solving math question:', error);
      throw new Error('An error occurred while solving the math question.');
    }
  };

  export const generateEssay = async (topic, paragraphs, educationLevel) => {
    // Get the current authenticated user
    const user = auth.currentUser;
  
    if (!user) {
      throw new Error('User not authenticated');
    }
  
    // Get the Firebase ID token
    const idToken = await user.getIdToken();
  
    try {
      // Send the essay generation request to the backend
      const response = await fetch('https://app.studentaitools.com/api/generate-essay', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`, // Pass Firebase token to backend
        },
        body: JSON.stringify({
          topic,
          paragraphs,
          educationLevel,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to generate essay');
      }
  
      const data = await response.json();
      return data.essay; // Return the generated essay
    } catch (error) {
      console.error('Error generating essay:', error);
      throw new Error('An error occurred while generating the essay.');
    }
  };
  