import React, { useState } from 'react';
import { getDatabase, ref, set, push } from "firebase/database";
import axios from 'axios';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ReactRecaptcha3 from 'react-google-recaptcha3';
import { motion } from 'framer-motion';

const NewsletterCard = () => {
  const [email, setEmail] = useState(''); // Email input state
  const [subscribed, setSubscribed] = useState(false); // Track if subscribed
  const [loading, setLoading] = useState(false); // Track loading state
  const database = getDatabase(); // Firebase DB

  const handleSubscribe = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when the API call starts

    try {
      // Get the reCAPTCHA token
      ReactRecaptcha3.getToken().then(
        async (recaptchaToken) => {
          console.log(recaptchaToken);

            const response = await axios.post('https://app.studentaitools.com/api/send-email', {
                email: email,
                subject: 'Welcome to StudentAITools',
                type: 'newsletter',
                recaptchaToken, // Send the reCAPTCHA token to the server for verification
            });
            if (response.status === 200) {
                setSubscribed(true);
                toast.success(`Thank you for subscribing to our Newsletter!`);
            }
            setLoading(false); // Set loading to false after API call completes
        },
        (error) => {
          console.log(error);
          setLoading(false); // Set loading to false if there is an error
        }
      );
    } catch (error) {
      toast.error("Failed to subscribe. Please try again.");
      setLoading(false); // Set loading to false if an exception occurs
    }
  };

  return (
    <motion.div className="max-w-5xl w-full bg-white shadow-lg rounded-lg p-8 my-8" 
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, ease: 'easeOut' }}
    viewport={{ once: true, amount: 0.2 }} >
      <h2 className="text-2xl font-semibold text-center mb-4">Subscribe to our Newsletter</h2>
      <p className="text-gray-600 text-center mb-6">
        Get the latest updates on our AI-powered tools for students. Stay ahead in your studies!
      </p>
      <form onSubmit={handleSubscribe}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="border p-2 rounded w-full mb-4"
          placeholder="Enter your email"
          required
        />
        <button
          type="submit"
          className="w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-700 transition duration-300"
          disabled={loading} // Disable the button while loading
        >
          {loading ? "Subscribing..." : "Subscribe"} {/* Change button text based on loading state */}
        </button>
      </form>
    </motion.div>
  );
};

export default NewsletterCard;