import React, { useState, useEffect, useRef  } from "react";  
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import { FaFileAlt, FaHtml5, FaFileWord, FaFilePdf } from 'react-icons/fa';
import * as docx from 'docx';
import { PDFDocument, rgb } from 'pdf-lib';

const Download = ({doc}) => {
    const [showDropdown , setShowDropdown] = useState("");
    const [isDropdownOpen, setIsDropdownOpen] = useState(false); // For tools dropdown
    const dropdownRef = useRef(null); // Reference for the dropdown

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false); // Close the dropdown
          }
        };
    
        document.addEventListener("mousedown", handleClickOutside);
    
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [dropdownRef]);

      const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
      };
    const handleDownload = async (fileType) => {
        let document = doc.data;

        if (!document) {
            toast.error("No content to download!");
            return;
        }

        const fileName = "generated-doc";
        let blob;

        if (fileType === 'txt') {
            blob = new Blob([document], { type: "text/plain" });
            saveAs(blob, `${fileName}.txt`);
        } else if (fileType === 'html') {
            blob = new Blob([document], { type: "text/html" });
            saveAs(blob, `${fileName}.html`);
        } else if (fileType === 'docx') {
            const doc = new docx.Document({
            sections: [{
                properties: {},
                children: [new docx.Paragraph({ text: document })],
            }],
            });
            const buffer = await docx.Packer.toBlob(doc);
            saveAs(buffer, `${fileName}.docx`);
        } else if (fileType === 'pdf') {
            const pdfDoc = await PDFDocument.create();
            const page = pdfDoc.addPage([600, 400]);
            const { height } = page.getSize();
            page.drawText(document, {
                x: 50,
                y: height - 50,
                size: 12,
                color: rgb(0, 0, 0),
            });

            const pdfBytes = await pdfDoc.save();
            blob = new Blob([pdfBytes], { type: 'application/pdf' });
            saveAs(blob, `${fileName}.pdf`);
        }
    }
    return (
        <div className="relative inline-block">
        <button
          onClick={toggleDropdown}
          className="bg-gray-600 text-white px-4 py-2 rounded-lg hover:bg-gray-700 transition duration-300"
        >
          Download
        </button>
        
        {isDropdownOpen && (
          <div className="absolute lg:right-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg" ref={dropdownRef} style={{zIndex: 999}}>
            <button
              onClick={() => handleDownload('txt')}
              className="flex items-center w-full px-4 py-2 text-gray-800 hover:bg-gray-100"
            >
              <FaFileAlt className="mr-2 text-gray-600" /> TXT
            </button>
            <button
              onClick={() => handleDownload('html')}
              className="flex items-center w-full px-4 py-2 text-gray-800 hover:bg-gray-100"
            >
              <FaHtml5 className="mr-2 text-orange-600" /> HTML
            </button>
            <button
              onClick={() => handleDownload('docx')}
              className="flex items-center w-full px-4 py-2 text-gray-800 hover:bg-gray-100"
            >
              <FaFileWord className="mr-2 text-blue-600" /> DOCX
            </button>
          </div>
        )}
      </div>
      
    );
};

export default Download;
